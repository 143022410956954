<script>
import { useCms } from '/~/composables/cms/use-cms'
import DesktopToolbarV1 from './desktop-toolbar.v1.vue'
import DesktopToolbarV2 from './desktop-toolbar.v2.vue'

export default {
  name: 'bill-payments-desktop-toolbar',
  functional: true,
  render(h, ctx) {
    const { leftMenuVersion } = useCms()

    let component

    if (leftMenuVersion.value === 'v1') {
      component = DesktopToolbarV1
    } else {
      component = DesktopToolbarV2
    }

    return h(component, ctx)
  },
}
</script>
