<script setup lang="ts">
import { nanoid } from 'nanoid'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms, Link, MenuItem } from '/~/composables/cms/use-cms'
import { useUI } from '/~/composables/ui'

const router = useRouter()
const { isDarkThemeForEwallet } = useCms()
const {
  leftMenuMobile,
  footerMenu: cmsFooterMenu,
  uiReady,
  lockKeyboardAccessArea,
  unlockKeyboardAccessArea,
} = useUI()

const keyboardAccessAreaId = 'mobile-menu'

const isToggled = ref(false)
const rootElement = ref<HTMLDivElement | null>(null)
const menuItemElement = ref<InstanceType<typeof BaseAction>[] | null>(null)

const expandedItems = ref<string[]>([])

function onCollapse(id: string) {
  const index = expandedItems.value.indexOf(id)

  if (expandedItemsHas(id)) {
    expandedItems.value.splice(index, 1)
  } else {
    expandedItems.value.push(id)
  }
}

const expandedItemsHas = (id: string) => expandedItems.value.indexOf(id) !== -1

const footerMenu = computed(() => {
  return [
    ...cmsFooterMenu.value,
    {
      id: nanoid(),
      enabled: true,
      label: 'Logout',
      'mobile-action': {
        type: 'action',
        value: 'logout',
      },
      icon: 'v2/heroic/logout',
    },
  ]
})

function onClose() {
  isToggled.value = false
}

function onAppMenuButtonClicked() {
  isToggled.value = true
}

function onLeftMenuClicked(action: Link) {
  if (action.type === 'action') {
    return
  }

  isToggled.value = false
}

router.afterEach(() => {
  isToggled.value = false
})

watch(isToggled, (isToggled) => {
  if (isToggled) {
    const menuItems: MenuItem[] = leftMenuMobile.value.concat(footerMenu.value)
    let focusElement: Element | null = null

    menuItems.some((item, index) => {
      if (
        item.action?.type === 'page' &&
        router.currentRoute.matched.some(
          ({ name }) => name === item.action.value
        )
      ) {
        focusElement = menuItemElement.value?.[index]?.$el ?? null
        return true
      }
    })

    lockKeyboardAccessArea({
      id: keyboardAccessAreaId,
      rootElement: rootElement.value,
      focusElement: focusElement,
      delay: 300,
    } as any)
  } else {
    unlockKeyboardAccessArea(keyboardAccessAreaId)
  }
})
</script>

<template>
  <div
    v-if="ui.mobile"
    ref="rootElement"
    class="fixed left-0 top-0 z-modal h-full w-full overflow-auto bg-white text-eonx-neutral-800 transition-all"
    :class="{
      'invisible opacity-0 delay-200': !isToggled,
    }"
    @keydown.esc="onClose"
  >
    <div
      class="w-full transform transition-all duration-300"
      :class="{
        '-translate-x-full': !isToggled,
        'delay-100': isToggled,
      }"
    >
      <div class="flex h-16 items-center justify-between px-6">
        <base-button
          aria-label="Close menu"
          icon="plain/close"
          size="lg"
          @click="onClose"
        />

        <app-header-logo class="mr-5 py-2.5" :max-width="115" />
        <div></div>
      </div>
      <template v-if="leftMenuMobile.length > 0">
        <div class="flex flex-col">
          <template
            v-for="{ id, action, icon, label, children } in leftMenuMobile"
          >
            <base-action
              v-if="uiReady && action && action.enabled"
              :key="id"
              ref="menuItemElement"
              v-bind="action"
              v-analytics:click="{ pageGroup: 'Mobile menu', cta: label }"
              :label="label"
              color=""
              :type="action.type"
              class="border-b"
              @collapse="onCollapse(id)"
              @click="onLeftMenuClicked(action, label)"
            >
              <div
                class="relative flex flex-row items-center gap-x-2.5 px-6 py-[15px] hover:text-primary active:bg-primary-lightest"
              >
                <span
                  class="flex items-center justify-center rounded-lg text-primary"
                >
                  <base-icon :svg="icon" :size="24" />
                </span>
                <span class="font-semibold">
                  {{ label }}
                </span>
                <div
                  v-if="children.length > 0"
                  class="pointer-events-none absolute right-0 top-1/2 mr-[15px] -translate-y-1/2 transform leading-3 text-eonx-neutral-800 transition-transform"
                  :class="{
                    'rotate-180 ': expandedItemsHas(id),
                  }"
                >
                  <base-icon svg="plain/chevron-down" size="sm" />
                </div>
              </div>
              <base-collapse
                v-if="children.length > 0"
                :value="expandedItemsHas(id)"
                :autoscroll="false"
                :full-width="false"
                class="-mt-[5px]"
              >
                <div class="relative my-2.5">
                  <template
                    v-for="{
                      id: idChildren,
                      action: actionChildren,
                      icon: iconChildren,
                      label: labelChildren,
                    } in children"
                  >
                    <base-action
                      v-if="uiReady && actionChildren && actionChildren.enabled"
                      :key="idChildren"
                      ref="menuItemElement"
                      v-bind="actionChildren"
                      :label="labelChildren"
                      color=""
                      :type="actionChildren.type"
                      class="flex h-16 w-full flex-row items-center gap-x-2.5 pl-14 hover:text-primary active:bg-primary-lightest"
                      @click="onLeftMenuClicked(actionChildren, labelChildren)"
                    >
                      <span class="flex items-center justify-center rounded-lg">
                        <base-icon :svg="iconChildren" :size="24" />
                      </span>
                      <span class="font-semibold">
                        {{ labelChildren }}
                      </span>
                      <div class="absolute left-[35px] h-full border-l"></div>
                    </base-action>
                  </template>
                </div>
              </base-collapse>
            </base-action>
          </template>
        </div>
      </template>
      <div class="space-y-4">
        <template v-if="uiReady && footerMenu.length > 0">
          <div class="mt-2.5 flex flex-col gap-x-[35px]">
            <template
              v-for="{
                id,
                icon,
                label,
                enabled,
                'mobile-action': mobileAction,
                divider,
              } in footerMenu"
            >
              <div
                v-if="divider"
                :key="id + 'divider'"
                class="w-full border-b-2"
              />
              <base-action
                v-else-if="enabled && mobileAction"
                :key="id"
                ref="menuItemElement"
                v-bind="mobileAction"
                :label="label"
                color=""
                :type="mobileAction.type"
                class="flex flex-row items-center gap-x-2.5 px-6 py-2.5 active:bg-primary-lightest"
                :class="{
                  'text-white': !isDarkThemeForEwallet,
                }"
                @click="onLeftMenuClicked(mobileAction, label)"
              >
                <template #entry>
                  <span
                    v-if="icon"
                    class="flex items-center justify-center rounded-lg text-eonx-neutral-800"
                  >
                    <base-icon :svg="icon" :size="24" />
                  </span>
                  <span class="text-eonx-neutral-800">
                    {{ label }}
                  </span>
                </template>
              </base-action>
            </template>
          </div>
        </template>
      </div>
    </div>
    <portal to="app-header-menu">
      <base-button
        aria-label="Open menu"
        icon="symbion/menu"
        size="lg"
        @click="onAppMenuButtonClicked"
      />
    </portal>
  </div>
</template>
